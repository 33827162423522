import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0,3,4];

export const dictionary = {
		"/": [~5],
		"/account": [~6,[2]],
		"/account/forgot-password": [7,[2]],
		"/account/forgot-password/[token]": [~8,[2]],
		"/account/login/[[secret]]": [~9,[2]],
		"/account/logout": [~10,[2]],
		"/account/signup": [~11,[2]],
		"/account/signup/[secret]": [~12,[2]],
		"/account/verify-email": [~13,[2]],
		"/design": [14],
		"/portal": [~15,[3]],
		"/portal/external/[secret]": [~18,[3]],
		"/portal/[applicationId]": [16,[3,4]],
		"/portal/[applicationId]/[documentId]": [~17,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';