import { init } from '@jill64/sentry-sveltekit-edge/client';
import { PUBLIC_STAGE } from '$env/static/public';
import { releaseDate } from '$lib/helpers';

const onError = init(
	'https://da5b98144fd70093c2c370cc5265f1e5@o656486.ingest.us.sentry.io/4506894206304256',

	{
		sentryOptions: {
			tracesSampleRate: 0.5,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
			environment: PUBLIC_STAGE || 'dev',
			release: releaseDate,
		},
		enableInDevMode: false,
	},
);

export const handleError = onError((e) => {
	// Your Error Handler
	console.log('Error:', e);
});
